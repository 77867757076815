<template>
  <div>
    <sidenav
      :is-sidebar-active.sync="isSidebarActive"
      :item-edit.sync="itemEdit"
      :is-add.sync="isAdd"
      @refetch-data="refetchData"
      :center-options="centerOptions"
      :sector-options="sectorOptions"
      :type-options="cameraTypeOptions"
      :unit-options="unitOptions"
      :vxg-master-account-options="vxgMasterAccountOptions"
      :reseller-options="resellerOptions"
    />

    <b-row>
      <b-col cols="6">
        <Breadcrumb
          :nameNav="nameNav"
          :itemFilter="itemFilter"
          :searchQuery="searchQuery"
          @itemFilterSearch="addItemFilterSearch"
        />
      </b-col>
      <b-col cols="6" class="pr-3 my-2 text-right">
        <button-filters
          @show-modal="$root.$emit('bv::show::modal', $event)"
          :filters-count="filtersCount"
          @clear-search-filters="clearSearchFilters"
        />
      </b-col>
    </b-row>
    <!-- Toastification loading content export -->
    <toastification-loading-content ref="loadingToast" />
    <!-- Filters -->
    <filter-modal
      @action="buildFilters()"
      @clear="clearSearchFilters()"
    >
    <template v-slot:main-filters>
      <filters
      :center-filter.sync="centerFilter"
      :center-options="centerOptions"
      :sector-filter.sync="sectorFilter"
      :sector-options="sectorOptions"
      :tenant-filter.sync="tenantFilter"
      :tenant-options="tenantOptions"
      :vxg-master-account-filter.sync="vxgMasterAccountFilter"
      :vxg-master-account-options="vxgMasterAccountOptions"
      :online-filter.sync="onlineFilter"
      :rtsp-available-filter.sync="rtspAvailableFilter"
      :reseller-options="resellerOptions"
      :reseller-filter.sync="resellerFilter"
    />
    </template>
    <template v-slot:footer-buttons>
      <b-button
        variant="secondary"
        size="sm"
        class="text-capitalize"
        :disabled="buttonUpdate"
        @click="updateBDCameras()"
        >
        {{ $tc('vxg_camera.update_from_vxg', 1) }}
      </b-button>
    </template>
    </filter-modal>

    <!-- Table Container Card -->
    <b-card-group deck class="mx-2">
    <b-card class="m-0" no-body>
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label class="text-capitalize">{{ $t("table.show") }}</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label class="text-capitalize">{{ $t("table.entries") }}</label>
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="5">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                data-cy="search-input"
                v-model="searchQuery"
                class="d-inline-block mr-1"
                :placeholder="$t('table.search') + '...'"
              />
              <b-button
                v-if="$can('create', 'CameraOmnicloud')"
                variant="secondary"
                @click="openAdd()">
                <span class="text-nowrap text-capitalize">
                  {{ $t("actions.add") + " " + $tc("camera.title", 1) }}
                </span>
              </b-button>
            </div>
          </b-col>
          <b-col cols="12" md="1">
            <div class="d-flex align-items-center justify-content-end">
              <b-button
                @click="exportExcel()"
                variant="success"
                class="text-capitalize"
                type="button"
                :disabled="buttonExport"
              >
                <feather-icon icon="DownloadCloudIcon" size="15" />
                <span class="text-nowrap text-capitalize"> </span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>
      <b-table
        ref="refListTable"
        class="position-relative"
        :items="fetchList"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        :empty-text="$t('no_record_found')"
        :sort-desc.sync="isSortDirDesc"
      >
        <template #head()="data">
          <span> {{ $tc(data.label, 1) }}</span>
        </template>

        <template #head(unit.description)="data">
          <span> {{ $tc(data.label, 2) }}</span>
        </template>

        <template #cell(access_token_all)="data">
          <span
            v-clipboard:copy="data.item.access_token_all"
            v-clipboard:success="onCopy"
            v-clipboard:error="onError"
            style="cursor: pointer"
          >
            {{ data.item.access_token_all }}
          </span>
        </template>

        <template #head(actions)="data">
          <span> {{ $tc(data.label, 2) }}</span>
        </template>

        <!-- Column: User -->
        <template #cell(name)="data">
          <div style="position: relative;
                        width: 250px;">
            <img :src="`${snapshotUrl}${data.item.camera.id}?access_token=${token}`"
              style="width: 250px; border-radius: 16px;"/>
            <div style="z-index: 1;
              position: absolute;
              width: -webkit-fill-available;
              color: white;
              background: rgba(0,0,0,.5);
              padding: 5px;
              top: 0;
              right: 0;
              left: 0;
              border-top-left-radius: 16px;
              border-top-right-radius: 16px;
            ">
              <b-media vertical-align="center">
                <template #aside>
                  <div :style="`background: ${data.item.camera.online ? 'green': 'red'};
                            width: 1.5rem;
                            height: 1.5rem;
                            border-radius: 1rem;
                            text-align: center;`">
                  </div>
                </template>
                <small style="font-weight: bolder; font-size: 14px;">{{ data.item.name }}</small>
              </b-media>
            </div>
          </div>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown variant="link" no-caret :right="$store.state.appConfig.isRTL">
            <template #button-content>
              <feather-icon
                data-cy="options"
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            <b-dropdown-item @click="updateStatusCamera(data.item.camera.id)">
              <feather-icon icon="RefreshCwIcon" />
              <span class="align-middle ml-50 text-capitalize"
                >{{ $t("actions.update") }}
              </span>
            </b-dropdown-item>

            <b-dropdown-item
              v-if="$can('update', 'CameraOmnicloud')"
              @click="openEdit(data.item)"
              >
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">{{ $t("actions.edit") }}</span>
            </b-dropdown-item>

            <b-dropdown-item
              v-if="$can('delete', 'CameraOmnicloud')"
              @click="confirmDelete(data.item.id)">
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50 text-capitalize"
                >{{ $t("actions.delete") }}
              </span>
            </b-dropdown-item>

            <b-dropdown-item v-if="data.item.camera.rtsp_available && $can('refresh_flow', 'CameraProxy')" @click="refreshRtpsFlow(data.item)">
              <feather-icon icon="CastIcon" />
              <span class="align-middle ml-50 text-capitalize"
                >{{ `${$t("actions.refresh")} RTSP` }}
              </span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted text-capitalize">{{
              $t("table.showing", {
                from: dataMeta.from,
                to: dataMeta.to,
                of: dataMeta.of,
              })
            }}</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <Pagination
              :totalList="totalList"
              :currentPage="currentPage"
              @updatePagination="update"
              :perPage="perPage"
            >
            </Pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </b-card-group>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BTable,
  BButton,
  BDropdown,
  BDropdownItem,
  BPagination,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { ref } from "@vue/composition-api";
import useList from "./useList";
import Sidenav from "./Sidenav.vue";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import store from "@/store";
import Filters from "./Filters.vue";
import Pagination from "@/components/Pagination.vue";
import Breadcrumb from "@/components/Breadcrumb.vue";
import ToastificationLoadingContent from "@core/components/toastification/ToastificationLoadingContent.vue";
import { useUtils as useI18nUtils } from "@core/libs/i18n";
import axios from 'axios';
import ButtonFilters from "@/components/Filters/ButtonFilters.vue";
import FilterModal from '@/components/Filters/FilterModal.vue'

export default {
  components: {
    Sidenav,
    Breadcrumb,
    Filters,
    Pagination,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    ToastificationLoadingContent,
    FilterModal,
    ButtonFilters,
  },
  props: {
    filters: {
      type: Object | Array,
    }
  },
  setup(_, context) {
    //start pagination
    const update = (data) => {
      currentPage.value = data;
    };
    //end pagination

    const snapshotUrl = `${process.env.VUE_APP_BASE_URL}/v1/services/snapshot/`

    const action = ref("list");
    const { t } = useI18nUtils();
    const trans = {
      "messages.export.download": t("messages.export.download"),
      "messages.export.Generating_file": t("messages.export.Generating_file"),
      "messages.error.server_error": t("messages.error.server_error"),
      "messages.delete.title": t("messages.delete.title"),
      "messages.delete.body": t("messages.delete.body"),
      "actions.delete": t("actions.delete"),
      "actions.cancel": t("actions.cancel"),
    };
    const exportExcel = () => {
      action.value == "export";
      buttonExport.value = true;
      loadingToast.value.show(
        trans["messages.export.download"],
        trans["messages.export.Generating_file"],
        "secondary"
      );
      store
        .dispatch("vxg_camera/export", {
          q: searchQuery.value,
          max: perPage.value,
          "page[n]": currentPage.value,
          sortBy: sortBy.value,
          sortDesc: isSortDirDesc.value,
          sector: sectorFilter.value,
          center: centerFilter.value,
          action: "export",
          vxg_master_account: vxgMasterAccountFilter.value
        })
        .then(() => {})
        .catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: trans["messages.error.server_error"],
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        })
        .finally(() => {
          loadingToast.value.close();
          buttonExport.value = false;
        });
    };
    const buttonExport = ref(false);
    // end export

    const isSidebarActive = ref(false);
    const itemEdit = ref({});
    const isAdd = ref(false);

    const openEdit = (item) => {
      isSidebarActive.value = true;
      itemEdit.value = item;
      isAdd.value = false;
    };

    const toast = useToast();

    const {
      fetchList,
      tableColumns,
      perPage,
      currentPage,
      totalList,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,

      refListTable,
      refetchData,
      sectorOptions,
      sectorFilter,
      centerOptions,
      centerFilter,
      unitOptions,
      tenantOptions,
      tenantFilter,
      cameraTypeOptions,
      vxgMasterAccountOptions,
      vxgMasterAccountFilter,
      onlineFilter,
      rtspAvailableFilter,
      updateBDCameras,
      buttonUpdate,
      loadingToast,

      clearSearchFilters,
      filtersCount,
      buildFilters,

      resellerOptions,
      resellerFilter,
    } = useList();

    const confirmDelete = function (id) {
      const self = this;
      const storeInner = store;
      this.$swal({
        title: context.parent.$i18n.t("messages.delete.title"),
        text: context.parent.$i18n.t("messages.delete.body"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: context.parent.$i18n.t("actions.delete"),
        cancelButtonText: context.parent.$i18n.t("actions.cancel"),
        customClass: {
          confirmButton: "btn btn-primary text-capitalize",
          cancelButton: "btn btn-outline-danger ml-1 text-capitalize",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          storeInner
            .dispatch("vxg_camera/delete", {id})
            .then((response) => {
              if (response.success) {
                toast({
                  component: ToastificationContent,
                  props: {
                    title: response.message,
                    icon: "CheckIcon",
                    variant: "success",
                  },
                });
                self.refetchData();
              } else {
                toast(
                  {
                    component: ToastificationContent,
                    props: {
                      title: response.message,
                      icon: "AlertTriangleIcon",
                      variant: "danger",
                    },
                  },
                  {
                    timeout: 10000,
                  }
                );
              }
            })
            .catch((response) => {
              toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: response.response.data.message,
                    icon: "AlertTriangleIcon",
                    variant: "danger",
                  },
                },
                {
                  timeout: 10000,
                }
              );
            });
        }
      });
    };

    const updateStatusCamera = (id) => {
      store
        .dispatch("camera_proxy/updateStatus", id)
        .then((response) => {
          if (response.success) {
            toast({
              component: ToastificationContent,
              props: {
                title: response.message,
                icon: "CheckIcon",
                variant: "success",
              },
            });
          } else {
            toast({
              component: ToastificationContent,
              props: {
                title: response.message,
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
          }
          refetchData();
        })
        .catch((response) => {
          toast({
            component: ToastificationContent,
            props: {
              title: response.response.data.message,
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        });
    };

    const openAdd = () => {
      isAdd.value = true;
      isSidebarActive.value = true;
    };

    const onCopy = () => {
      toast({
        component: ToastificationContent,
        props: {
          title: "Copiado correctamente",
          icon: "ClipboardIcon",
          variant: "success",
        },
      });
    };

    const onError = () => {
      toast({
        component: ToastificationContent,
        props: {
          title: "No se pudo copiar",
          icon: "AlertTriangleIcon",
          variant: "danger",
        },
      });
    };

    const refreshRtpsFlow = (camera) => {
      axios.get(`${process.env.VUE_APP_MS_RTSP_URL}refresh`,
        {
          params: { id: camera.camera.id },
          headers: {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
            'X-Host': process.env.VUE_APP_BASE_URL
          },
      })
        .then(result => {
          toast({
              component: ToastificationContent,
              props: {
                title: 'Actualizadó correctamente',
                icon: "CheckIcon",
                variant: "success",
              },
            });
        });
      }

    // start breadcrumbs
    const itemFilter = ref();
    const addItemFilter = (data) => {
      itemFilter.value = data;
    };
    const addItemFilterSearch = (data) => {
      data.forEach((element) => {
        if (nameNav.value === element.text) {
          searchQuery.value = element.searchQuery;
        }
      });
    };
    const nameNav = ref("breadcrumbs.vass.omnicloud.cameras");
    // end breadcrumbs

    return {
      loadingToast,
      action,
      exportExcel,
      buttonExport,
      itemFilter,
      addItemFilter,
      addItemFilterSearch,
      nameNav,
      isSidebarActive,
      isAdd,
      itemEdit,
      openEdit,
      openAdd,
      confirmDelete,
      fetchList,
      tableColumns,
      perPage,
      currentPage,
      totalList,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refListTable,
      refetchData,
      sectorOptions,
      sectorFilter,
      centerOptions,
      centerFilter,
      unitOptions,
      update,
      onCopy,
      onError,
      tenantOptions,
      tenantFilter,
      cameraTypeOptions,
      vxgMasterAccountOptions,
      vxgMasterAccountFilter,
      onlineFilter,
      rtspAvailableFilter,
      refreshRtpsFlow,
      snapshotUrl,
      token: localStorage.accessToken,
      updateStatusCamera,
      updateBDCameras,
      buttonUpdate,

      filtersCount,
      buildFilters,
      clearSearchFilters,

      resellerOptions,
      resellerFilter,
    };
  },
};
</script>
