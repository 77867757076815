import { ref, computed, watch } from '@vue/composition-api'
// import { title } from '@core/utils/filter'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import moment from 'moment-timezone'
import store from '@/store'
import router from '@/router'
import { useUtils as useI18nUtils } from "@core/libs/i18n";
import useFilterCounter from '@/components/Filters/useFiltersCount'
import { debounce } from 'lodash'

export default function useList() {
  const { t } = useI18nUtils();
  const trans = {
    'messages.error.server_error': t('messages.error.server_error')
  };
  const refListTable = ref(null)

  const toast = useToast()

  // ==================== Tables

  const tableColumns = [
    { label: 'actions.name', key: 'actions' },
    { label: 'vxg_camera.name', key: 'name', sortable: true },
    { label: 'tenants.title', key: 'camera.units[0].router.tenant.name', sortable: false },
    { label: 'unit.title', key: 'camera.units', sortable: false,
      formatter: (value, key, item) => {
        if (value) return value.map(item => { return item.description }).join(', ')
        return ""
      }
    },
    { label: 'centers.title', key: 'center.name', sortable: true },
    { label: 'sectors.title', key: 'sector.name', sortable: true },
    { label: 'camera_proxy.last_connection', key: 'camera.last_connection', sortable: true },
    { label: 'camera_proxy.id', key: 'camera.id', sortable: true },
    { label: 'device_tag.title', key: 'camera.device_tag.name', sortable: false },
    { label: 'vxg_camera.id_vxg', key: 'id_vxg', sortable: true },
    { label: 'camera.previous_time_of_prealarma', key: 'delayed_record_minutes', sortable: true },
    { label: 'camera.later_time_of_prealarma', key: 'forward_record_minutes', sortable: true },
    { label: 'camera.type', key: 'camera_type.type', sortable: true },
    {
      label: 'generic_devices.reseller',
      key: 'omniview_reseller_name'
    },
    { label: 'vxg_camera.live_stream', key: 'live_stream', sortable: true },
    { label: 'vxg_camera.recording_stream', key: 'recording_stream', sortable: true },
    { label: 'vxg_camera.rec_mode', key: 'rec_mode', sortable: true },
    { label: 'vxg_camera.access_token_watch', key: 'access_token_watch', sortable: true, tdClass: 'text-truncate token-td' },
    { label: 'vxg_camera.access_token_all', key: 'access_token_all', sortable: true, tdClass: 'text-truncate token-td' },
    { label: 'vxg_camera.streaming', key: 'streaming', sortable: true },
    { label: 'vxg_camera.timezone', key: 'timezone', sortable: false },
    { label: 'vxg_master_account.title', key: 'vxg_master_account.name', sortable: false },
    { label: 'camera_proxy.rtsp_available', key: 'camera.rtsp_available', sortable: true },
    {
      label: 'date.created_at', key: 'created', sortable: true, formatter: 'formatDateAssigned',
    },
    {
      label: 'date.updated_at', key: 'updated_at', sortable: true, formatter: 'formatDateAssigned',
    },
  ]
  const perPage = ref(10)
  const totalList = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)

  const dataMeta = computed(() => {
    const localItemsCount = refListTable.value ? refListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalList.value,
    }
  })
  // ========== Getters Filters
  store.dispatch('sector/getAll')
  const sectorOptions = computed(() => store.getters['sector/getSelectedItems'])
  const sectorFilter = ref(null)

  if (router.currentRoute.params.sectorFilter)
    sectorFilter.value = parseInt(vxg_camera.currentRoute.id_sector)

  store.dispatch('center/getAll')
  const centerOptions = computed(() => store.getters['center/getSelectedItems'])
  const centerFilter = ref(null)

  if (router.currentRoute.params.centerFilter)
    centerFilter.value = parseInt(vxg_camera.currentRoute.id_center)

  store.dispatch('tenant/getAll')
  const tenantOptions = computed(() => store.getters['tenant/getSelectedItems'])
  const tenantFilter = ref(null)

  store.dispatch('unit/getAll', { with: 'router:id,id_tenant' })
  const unitOptions = computed(() => store.getters['unit/getSelectedItems'])

  store.dispatch('camera_type/getAll')
  const cameraTypeOptions = computed(() => store.getters['camera_type/getSelectedItems'])

  store.dispatch('vxg_master_account/getAll')
  const vxgMasterAccountOptions = computed(() => store.getters['vxg_master_account/getSelectedItems'])
  const vxgMasterAccountFilter = ref(null)

  store.dispatch('omniview_reseller/getAll');
  const resellerOptions = computed(() => store.getters['omniview_reseller/getSelectedItems'])
  const resellerFilter = ref(null)

  const onlineFilter = ref(null)
  const rtspAvailableFilter = ref(null)

  const fetchList = (ctx, callback) => {
    store.dispatch('vxg_camera/getAll', {
      q: searchQuery.value,
      max: perPage.value,
      'page[n]': currentPage.value,
      sortBy: sortBy.value,
      sortDesc: isSortDirDesc.value,
      sector: sectorFilter.value,
      center: centerFilter.value,
      id_tenant: tenantFilter.value,
      vxg_master_account: vxgMasterAccountFilter.value,
      online: onlineFilter.value,
      rtsp_available: rtspAvailableFilter.value,
    })
    .then(response => {
      callback(response.data)
      totalList.value = response.meta.total
      if(store.getters["vxg_camera/getSelectAll"]){
        setTimeout(() => {
          checkAll();
        }, 400);
      }
    })
    .catch(() => {
      toast({
        component: ToastificationContent,
        props: {
          title: trans['messages.error.server_error'],
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
    })
  }

  const buttonUpdate = ref(false);
  const loadingToast = ref(null);
  const updateBDCameras = function ()  {
    const storeInner = store
    buttonUpdate.value = true;
    loadingToast.value.show("Actualizando", `Actualizando cámaras ...`, "secondary");
    storeInner.dispatch('vxg_camera/updateDBCameras')
      .then(response => {
        if (response.success) {
          toast({
            component: ToastificationContent,
            props: {
              title: response.message,
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
          emit('refetch-data')
        } else {
          toast({
            component: ToastificationContent,
            props: {
              title: response.message,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          },
          {
            timeout: 10000
          })
        }
      }).catch(response => {
        console.log({response});
        toast({
          component: ToastificationContent,
          props: {
            title: response.response.data.message,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        },
        {
          timeout: 10000
        })
      }).finally(() => {
        buttonUpdate.value = false;
        loadingToast.value.close();
      })
    }

  const formatDateAssigned = value => {
    const formattedDate = moment(value).format('DD/MM/YYYY HH:mm:ss')
    return formattedDate
  }

  const refetchData = () => {
    refListTable.value.refresh()
  }

  const checkAll = () => {
    let camerasCheckbox = document.querySelectorAll('.camera-checkbox input[type=checkbox]');
    camerasCheckbox.forEach((checkbox) => checkbox.checked = true);
  }

  watch([
    currentPage,
    perPage,
    // vxgMasterAccountFilter,
    // tenantFilter,
    // sectorFilter,
    // centerFilter,
    // onlineFilter,
    // rtspAvailableFilter,
  ], () => {
    refetchData()
  })

  watch([
    searchQuery
  ], debounce(() => {
    refetchData()
    }, 500))

  // filters counter ============================
  const filters = ref({});
  const filtersCount = useFilterCounter(filters);
  const buildFilters = () => {
    filters.value = {
      center: centerFilter.value,
      tenant: tenantFilter.value,
      sector: sectorFilter.value,
      vxg_master_account: vxgMasterAccountFilter.value,
      online: onlineFilter.value,
      rtsp_available: rtspAvailableFilter.value,
    };
    refetchData();
  }
  const clearSearchFilters = () => {
    centerFilter.value = null;
    tenantFilter.value = null;
    sectorFilter.value = null;
    vxgMasterAccountFilter.value = null,
    onlineFilter.value = null;
    rtspAvailableFilter.value = null;
    resellerFilter.value = null;
    buildFilters();
    filters.value = ({});
  }
  // ============================================

  return {
    tenantOptions,
    tenantFilter,

    currentPage,
    dataMeta,
    isSortDirDesc,
    perPage,
    perPageOptions,
    searchQuery,
    sortBy,
    tableColumns,
    totalList,

    fetchList,
    updateBDCameras,
    buttonUpdate,
    loadingToast,
    refListTable,
    formatDateAssigned,

    centerFilter,
    onlineFilter,
    rtspAvailableFilter,
    sectorFilter,
    vxgMasterAccountFilter,
    cameraTypeOptions,
    centerOptions,
    sectorOptions,
    unitOptions,
    vxgMasterAccountOptions,

    resellerOptions,
    resellerFilter,

    refetchData,
    checkAll,

    filtersCount,
    buildFilters,
    clearSearchFilters
  }
}
