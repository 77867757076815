<template>
  <div>
    <b-row align-v="center" class="d-flex align-items-center">
      <b-col cols="6" class="mb-2">
        <label class="text-capitalize">
            {{ $tc('centers.title', 2) }}
          </label>
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="centerFilter"
            :options="centerOptions"
            class="w-100"
            multiple
            :reduce="(val) => val.value"
            @input="(val) => $emit('update:centerFilter', val)"
            append-to-body
            :calculate-position="withPopper"
          />
      </b-col>
      <b-col cols="6" class="mb-2">
      <label class="text-capitalize">
          {{ $tc('sectors.title', 2) }}
        </label>
        <v-select
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          :value="sectorFilter"
          :options="sectorOptions"
          class="w-100"
          multiple
          :reduce="(val) => val.value"
          @input="(val) => $emit('update:sectorFilter', val)"
          append-to-body
          :calculate-position="withPopper"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="6" class="mb-2">
        <label class="text-capitalize">{{ $tc('tenants.title', 1) }}</label>
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="tenantFilter"
            :options="tenantOptions"
            class="w-100"
            multiple
            :reduce="val => val.value"
            @input="(val) => $emit('update:tenantFilter', val)"
            append-to-body
            :calculate-position="withPopper"
          />
      </b-col>
      <b-col
        cols="6"
        class="mb-2"
      >
      <label class="text-capitalize">{{ $tc('vxg_master_account.title', 1) }}</label>
        <v-select
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          :value="vxgMasterAccountFilter"
          :options="vxgMasterAccountOptions"
          class="w-100"
          multiple
          :reduce="val => val.value"
          @input="(val) => $emit('update:vxgMasterAccountFilter', val)"
          append-to-body
          :calculate-position="withPopper"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="6" class="mb-2 text-capitalize">
        <label class="text-capitalize">{{ $t('camera.online') }}</label>
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="onlineFilter"
            class="w-100"
            :reduce="val => val.value"
            :options="[{label: 'online', value: true}, {label: 'offline', value: false}]"
            @input="(val) => $emit('update:onlineFilter', val)"
            append-to-body
            :calculate-position="withPopper"
          />
      </b-col>
      <b-col cols="6" class="mb-2">
        <label class="text-capitalize">{{ $t('camera_proxy.rtsp_available') }}</label>
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="rtspAvailableFilter"
            class="w-100"
            :reduce="val => val.value"
            :options="[{label: 'enabled', value: true}, {label: 'disabled', value: false}]"
            @input="(val) => $emit('update:rtspAvailableFilter', val)"
            append-to-body
            :calculate-position="withPopper"
          />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardBody,
  BRow,
  BCol,
  BButton,
  BFormCheckbox,
} from "bootstrap-vue";
import vSelect from "vue-select";
import popperHelper from '@/libs/popper-helper';

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    BButton,
    BFormCheckbox,
    vSelect,
  },
  props: {
    centerOptions: {
      type: Array,
      default: null,
    },
    centerFilter: {
      type: Array,
      default: null,
    },
    sectorOptions: {
      type: Array,
      default: null,
    },
    sectorFilter: {
      type: Array,
      default: null,
    },
    tenantOptions: {
      type: Array,
      default: null,
    },
    tenantFilter: {
      type: Array,
      default: null,
    },
    vxgMasterAccountOptions: {
      type: Array,
      default: null,
    },
    vxgMasterAccountFilter: {
      type: Array,
      default: null,
    },
    onlineFilter: {
      type: Boolean,
      default: false,
    },
    rtspAvailableFilter: {
      type: Boolean,
      default: false,
    },
    buttonUpdate: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    return {
      withPopper: popperHelper.withPopper,
    }
  }

};
</script>
